/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"

import Header from "./header/header"
import "./../css/reset.css"
import "./../css/common.css"
import "./../css/toastr.css"
import "./../css/layout.css"

const Layout = ({ children, page }) => {

  return (
    <>
      <Helmet>
        <script>
        {`(function(w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js'
          });
          var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-PVG733KD');`}
      </script>
        <link href="https://fonts.googleapis.com/icon?family=Material+Icons"
        rel="stylesheet" />
      </Helmet>
      <Header page={page} />
      <div>
        <noscript>
          <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PVG733KD" height="0" width="0" style={{display:'none', visibility:'hidden'}}></iframe>
        </noscript>
        <main>{children}</main>
        <footer>
          <span role="img" aria-label="world">🌏 </span>
          Why not change the world?
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
