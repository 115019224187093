import PropTypes from "prop-types"
import React from "react"

import lecturesData from "./../../../data/lectures.yaml"

import logo from "./../../../images/interface/header-logo-new.png"
import menu from "./../../../images/interface/menu.png"

import "./../../css/header/header.css"

import { defineCustomElements as deckDeckGoHighlightElement } from '@deckdeckgo/highlight-code/dist/loader'
deckDeckGoHighlightElement()

class Header extends React.Component {

  page
  state = {
    menuOn: false
  }

  constructor(props) {
    super(props)
    this.page = props.page
  }

  insertDiscounts () {
    const count = lecturesData.filter(lecture => {
      if (!lecture.discount) return false
      const today = new Date()
      const dateFrom = new Date(lecture.discount.split('~')[0])
      const dateTo = new Date(lecture.discount.split('~')[1] + ' 23:59:59')
      return today > dateFrom && today < dateTo
    }).length
    return (
      count > 0 && (
        <span className="discount">할인 {count}</span>
      )
    )
  }
  insertNew () {
    return (
      <span className="new">NEW</span>
    )
  }

  render () {
    let page = this.page

    return (
      <header>
        <div id="inner-header">
          <h1>
            <a href="/">
              <img id="header-logo" src={logo} alt="Logo" />
            </a>
          </h1>
          <nav className={this.state.menuOn ? 'on' : ''}>
            <div
              role="button"
              onClick={() => { this.setState(() => { return { menuOn: false }}) }}
              onKeyDown={() => { this.setState(() => { return { menuOn: false }}) }}
              tabIndex={0}
            >
              <ul>
                <li className={'videos ' + (page==='home' ? 'selected' : '')}>
                    <a href="/">얄코 홈</a>
                </li>
                <li className={'lectures ' + (page==='lectures' ? 'selected' : '')}>
                    <a href="/#lectures">장편강좌 {this.insertDiscounts()}</a>
                </li>
                <li className={'book ' + (page==='book' ? 'selected' : '')}>
                  <a href="/#books" rel="noreferrer">
                    얄코 책
                    {this.insertNew()}
                  </a>
                </li>
                {/* <li className={'stickers ' + (page==='stickers' ? 'selected' : '')}>
                  <a href="https://marpple.shop/kr/yalco" target="_blank" rel="noreferrer">
                    굿즈
                  </a>
                </li> */}
                <li className={'stickers ' + (page==='stickers' ? 'selected' : '')}>
                  <a href="https://www.youtube.com/channel/UC2nkWbaJt1KQDi2r2XclzTQ" target="_blank" rel="noreferrer">
                  <span className="material-icons">
                    play_circle_filled
                  </span> 유튜브 채널
                  </a>
                </li>
              </ul>
            </div>
          </nav>
          <div id="menu"
            role="button"
            onClick={() => { this.setState(() => { return { menuOn: !this.state.menuOn }}) }}
            onKeyDown={() => { this.setState(() => { return { menuOn: !this.state.menuOn }}) }}
            tabIndex={0}
          >
            <img src={menu} alt="mobile-menu"></img>
          </div>
        </div>
      </header>
    )
  }
}

// const Header = ({page}) => (
// )

Header.propTypes = {
  page: PropTypes.string,
}

Header.defaultProps = {
  page: ``,
}

export default Header
